@import '../App/App.scss';

.loader-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background-color: $bg-shape;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper {
  display: grid;
  place-content: center;
  h1 {
    position: absolute;
    top: 45%;
    left: 45%;
    font-size: 50px;
    font-weight: 600;
    @media (max-width: 768px) {
      left: 27%;
    }
  }
}

.intro {
  font-size: 44px;
}
