@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');
//variabile
$btn: #755b33;
$header: #462901;
$text: #989782;
$faded: #f1efeb;
$bg-shape: #f1ede8;
$links: #7d643f;

html,
body {
  font-family: 'Raleway' !important;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden !important;
  position: relative;
  p {
    font-size: 20px;
  }
}

.fallback {
  width: 100vw;
  height: 100vh;
}

.four-o-four {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;

  h1 {
    line-height: 1.3;
    font-size: 3.7vw;
    font-weight: 600;
    position: relative;
    bottom: 100px;
    color: $header;
    @media (max-width: 768px) {
      font-size: 34px;
    }
  }
  p {
    line-height: 1.7;
    margin-top: 25px;
    border: 2px solid $header;
    padding: 30px 30px;

    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: $header;
      color: white;
    }
  }
}
