@import '../App/App.scss';

.main-container-big {
  margin: 0 auto;
}

// margin: 150px 9vw 0 9vw;

.main-container {
  width: 100vw;
  display: flex;
  margin-top: 150px;
  justify-content: space-around;
  align-items: center;

  // @media (max-width: 768px) {
  //   display: block;
  // }
  .text-side {
    margin: 20px 0 20px 0;
    width: 40vw;
    display: grid;
    place-items: center;
    @media (max-width: 768px) {
    }
    .text-wrap {
      width: 30vw;
    }
    h1 {
      line-height: 1.3;
      font-size: 3.7vw;
      font-weight: 600;
      color: $header;
      @media (max-width: 768px) {
        font-size: 34px;
      }
    }
    p {
      line-height: 1.7;
      margin-top: 25px;
    }
  }

  .img-side {
    margin-right: 120px;
    display: block;

    @media (max-width: 768px) {
      margin-top: 100px;
      width: 100%;
      margin-left: 9vw;
      display: grid;
      place-content: center;
      position: relative;
      right: 11vw;
      top: 80px;
    }

    .img-wrapper {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 350px;
        height: 410px;
        bottom: 0px;
        left: 45px;
        bottom: 30px;
        z-index: 2;
        border: 3px solid $links;
        border-top-left-radius: 80px;
        border-top-right-radius: 80px;

        @media (max-width: 964px) {
          height: 325px;
          width: 280px;
          left: 30px;
          bottom: 30px;
        }
      }
    }

    img {
      width: 350px;
      height: 410px;
      border-top-left-radius: 80px;
      border-top-right-radius: 80px;
      transition: 0.2s all ease-in-out;

      @media (max-width: 964px) {
        height: 325px;
        width: 280px;
      }

      // &:hover {
      //   transform: scale(1.2);
      // }
    }
  }

  button {
    border: 2px solid $header;
    margin-top: 25px;
    float: left;
    height: 60px;
    width: 280px;
    padding: 10px;
    background-color: $btn;
    color: white;
    border: none;
    font-size: 18px;
    font-family: inherit;
    text-align: center;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: white;
      cursor: pointer;
      border: 2px solid $header;
      color: $header;
    }
  }
}

.big-circle {
  position: absolute;
  background-color: $bg-shape;
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  right: -100px;
  top: -100px;
  z-index: -1;
}

.small-circle {
  position: absolute;
  background-color: $bg-shape;
  width: 14vw;
  height: 14vw;
  border-radius: 50%;
  left: 100px;
  top: 100px;
  z-index: -1;
}

@media (max-width: 768px) {
  .main-container-big {
    max-width: 100vw !important;
  }
  .main-container {
    margin-left: 9vw;
    margin-right: 5vw;
    width: 100%;
    max-width: 81vw;
    display: block;

    .text-side {
      width: 100%;
      display: block;
      .text-wrap {
        width: 100%;
      }
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 18px;
      }
    }
    button {
      margin: 25px auto;
      width: 140px;
      font-size: 14px;
    }
  }
  .img-side {
    width: 80vw;
    margin-top: 100px;
    margin-left: 10vw;
    display: grid;
    place-items: center;
    img {
      position: relative;
      margin-left: 4vw;
    }
  }

  .big-circle {
    position: absolute;
    width: 60vw;
    height: 60vw;
    right: -90px;
    top: -100px;
  }

  .small-circle {
    width: 40vw;
    height: 40vw;
    left: 100px;
    top: 200px;
  }
}

.circle-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 70vh;
  width: 100vw;
  z-index: -1;
}


.main-img {
  width: 100%;
  height: auto;
}