@import '../App/App.scss';

.despre-container {
  max-width: 100vw;
  width: 100%;
  display: flex;
  margin-top: 150px;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }

  .despre-text {
    margin-right: 9vw;
    width: 40vw;

    @media (max-width: 768px) {
      width: 100%;
      margin-left: 9vw;
      margin-right: 9vw;
      max-width: 85vw;
      display: grid;
      place-content: center;
    }

    h1 {
      line-height: 1.3;
      font-size: 3.7vw;
      font-weight: 600;
      color: $header;
      @media (max-width: 768px) {
        font-size: 40px;
      }
    }

    p {
      line-height: 1.7;
      margin-top: 25px;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .despre-poza {
    margin-left: 9vw;
    width: 40vw;

    @media (max-width: 968px) {
      width: 100%;
      display: grid;
      margin: 0;
      place-content: center;
    }

    .img-wrapper {
      position: relative;
      bottom: 70px;

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 350px;
        height: 410px;
        bottom: 0px;
        left: -45px;
        bottom: 30px;
        z-index: 2;
        border: 3px solid $links;
        border-bottom-left-radius: 80px;
        border-bottom-right-radius: 80px;

        @media (max-width: 964px) {
          height: 325px;
          width: 280px;
          left: -25px;
          bottom: 30px;
        }
      }
    }

    img {
      margin: 0 auto;
      width: 350px;
      height: 410px;
      border-bottom-left-radius: 80px;
      border-bottom-right-radius: 80px;
      transition: 0.2s all ease-in-out;

      @media (max-width: 964px) {
        height: 325px;
        width: 280px;
      }
    }
  }
}

.despre-shapes {
  width: 100%;
  height: 30vh;
  position: relative;
  bottom: 300px;
  z-index: -1;
  .despre-circle-big {
    width: 10vw;
    height: 10vw;
    background-color: $bg-shape;
    border-radius: 50%;
    @media (max-width: 768px) {
      width: 35vw;
      height: 35vw;
    }
  }
  .despre-circle-small {
    width: 6vw;
    height: 6vw;
    background-color: $bg-shape;
    border-radius: 50%;
    position: relative;
    left: 200px;
    @media (max-width: 768px) {
      width: 20vw;
      height: 20vw;
      top: 100px;
    }
  }
}

b {
  font-weight: 600;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.icons {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  p {
    position: relative;
    bottom: 15px;
    font-weight: 600;
  }

  img {
    width: 35px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    margin-top: 70px;
    align-items: center;
    justify-content: center;

    position: relative;
    right: 3vw;

    p {
      position: relative;
      bottom: 20px;
      text-align: center;
    }

    img {
      margin-left: 20px;
      margin-right: 20px;
      width: 50px;
      margin-top: 20px;
    }
  }
}

.icons-wrapper {
  width: 100px;
  margin: 0 auto;
  display: grid;
  place-content: center;
}

.finantare-container {
  max-width: 600px;
  margin: auto auto;
  position: relative;
  bottom: 170px;

  @media (max-width: 768px) {
    max-width: 88%;
    bottom: 0;
    padding-bottom: 100px;
  }
}
ul {
  list-style: disc !important;
  li {
    margin: 10px;
    line-height: 1.2;
  }
}

.finantare {
  width: 100%;
  margin: 35px auto;
  display: block;
}
