@import '../App/App.scss';

.nav-desktop {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .links {
    margin-left: 10vw;
    position: relative;
    right: 20px;
    display: flex;
    color: $links;
    div {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 10px 20px;
      position: relative;
      &::after {
        bottom: -5px;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: $links;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
    div:hover::after {
      width: 110%;
      left: -5%;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.nav-mobile {
  display: flex;
  height: 70px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: baseline;

  .nav-el {
    margin-left: 30px;
    font-size: 24px;
  }
  .hamburger {
    margin-right: 40px;
  }
}

.logo {
  font-size: 2rem;
  font-weight: 600;

  position: relative;

  @media (max-width: 768px) {
    font-size: 1.7rem;
    font-weight: 600;
  }
}

//@at-root

.sandwich_container {
  display: none;
  width: 49px;
  height: 49px;
  position: sticky;
  z-index: 5;
  margin-right: 20px;
  border-radius: 100%;
  margin-bottom: 5px;
}

a {
  color: black !important;
  text-decoration: none !important;
}

.line1,
.line2 {
  background-color: black !important;
  width: 30px !important;
  height: 3px;
  border-radius: 25px;
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.line1 {
  background-color: black;
  position: relative;
  top: 19px;
  left: 0px;
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.line2 {
  margin-top: 5px;
  position: relative;
  top: 19px;
  left: 0px;
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.sandwich_wrapper.move {
  transform: translateX(-5px);
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.line1.black {
  background-color: black;
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.line2.black {
  background-color: black;
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.line1.clicked {
  transform: rotate(45deg) translate(6px, 0px);
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.line2.clicked {
  transform: rotate(-45deg) translate(6px, 0px);
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

.relative-side {
  position: relative;
  top: -100px;
}

.sideBar {
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: $faded;
  z-index: 4;
  display: grid;
  place-content: center;

  div {
    margin-bottom: 5vh;
    font-size: 34px;
    font-weight: 600;
  }
}

.translate {
  transform: translateY(-65px);
  transition: 1s all cubic-bezier(0.19, 1, 0.22, 1);
}

@media only screen and (max-width: 1240px) {
  .sideBar {
    width: 100vw;
    overflow: hidden;
  }
  .sandwich_container {
    display: block;
    position: sticky;
  }
  .sandwich_container {
    top: 55px;
    left: 85vw;
  }
}

.horizontal-line {
  background-color: black !important;
  height: 1px !important;
  position: relative;
  bottom: 3vh;
}

.social-side {
  a {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.facebook-m {
  margin-top: 30px;
}

.sideBar-wrapper {
  margin-right: 10vw;
}


.firma-de-aur {
  position: absolute;
  left: -15px;
  margin-top: 10px;
}

.firma-de-aur-mobil {
  margin-top: 65px;
  margin-left: 25px;
}